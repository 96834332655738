<template>
<div>

  <div v-show="false">
    <template v-for="(entity, keyE) in $store.state.map.results">
      <PopupMarkerEntity :entity="entity" :key="keyE"/>
    </template>
  </div>

  <l-map
    ref="map"  
    :options="{ scrollWheelZoom:true, zoomControl: false }"
    :zoom="zoom"  
    :maxZoom="13"
    :minZoom="4"
    :center="mapCenter"  
    id="map-container"
    style="" 
    :style="'height:'+($vuetify.breakpoint.height-menuTopHeight)+'px;'"
  >
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>

      <v-marker-cluster ref="markerCluster" :options="clusterOptions"></v-marker-cluster>

      <l-marker color="red" :icon="markerMain" 
                :lat-lng="[lat, lng]"
                ref="rootMarker"
                draggable 
                @dragend="dragend"
                @drag="rootMarkerDrag" >
          <l-popup :open="true" :width="400" class="pa-4 text-center" ref="popup">
            <b>Déplacez l'icone<br>sur votre zone de recherche</b><br>
            {{ cityAddressPopup }}
            <v-btn block color="secondary"
                  elevation="0" @click="$root.$emit('fetchMap')"
                  class="mt-4">
              <v-icon small>mdi-refresh</v-icon> Actualiser les résultats
            </v-btn>
            <div class="text-left">
            <!-- LAT: {{ lat }}<br>LNG: {{ lng }} -->
            </div>
          </l-popup>
      </l-marker>

      <!-- MAIN CIRCLE TO SHOW ROOT RADIUS -->
      <l-circle :lat-lng="[lat, lng]" 
                :radius="rootCircle.radius"
                color="rgba(84, 97, 173, 0.5)"
                fill-color="rgba(84, 97, 173)"
                :fill-opacity=".35" ref="rootCircle">
      </l-circle>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-text-field dense outlined solo elevation="0" 
                        hide-details v-model="searchPlaceStr"
                        class="text-search-geo"
                        placeholder="Rechercher un lieu, une adresse..."
                        :loading="loadPlaces"
                        v-bind="attrs" v-on="on" 
                        @change="searchPlace()"
                        append-icon="mdi-magnify"
                        @click:append="searchPlace()"
                        style="">
          </v-text-field>
        </template>

        <ListNominatimResult :places="places" :loadPlaces="loadPlaces" :onclick="setNewCenter"/>
        
      </v-menu>

      <v-btn small class="d-md-none open-list-res-map" 
              fab style="z-index:1000;"
              @click="$root.$emit('openDialogResMap')">
        <v-icon small>mdi-magnify</v-icon>
      </v-btn>

      <v-btn color="secondary"
            elevation="0" @click="$root.$emit('fetchMap')"
            class="mt-4 btn-tool-map tool-map2">
        <v-icon small>mdi-refresh</v-icon> Actualiser les résultats
      </v-btn>
      <v-btn elevation="0" @click="$root.$emit('closeDialogSearchGeo')"
            class="mt-4 btn-tool-map tool-map1">
        <v-icon small>mdi-format-list-checkbox</v-icon> Afficher la liste
      </v-btn>

  </l-map>

  <v-slider v-model="rootRadius" 
            :min="1000" :max="800000" 
            @change="updateRadius"
            color="secondary"
            inverse-label class="slider-geo-block"
            :label="parseInt(rootRadius/1000)+' km'"
            :step="1000"
  >
      <template v-slot:prepend>
        <v-icon @click="decrement">mdi-minus</v-icon>
      </template>

      <template v-slot:append>
        <v-icon @click="increment">mdi-plus</v-icon>
      </template>
  </v-slider>

  <v-tabs icons-and-text
          class="toolbar-map-bootom" grow
          :style="'width:'+mapWidth+'px;'">
    <!-- <v-tab>Créer<v-icon small>mdi-plus</v-icon></v-tab> -->
    <v-tab>
      <v-progress-linear class="mt-2" value="15" color="red"></v-progress-linear>
      <small><v-icon >mdi-party-popper</v-icon> 15%</small>
      
    </v-tab>
    <v-tab>
      <v-progress-linear class="mt-2" value="40" color="green"></v-progress-linear>
      <small><v-icon >mdi-bottle-tonic-plus</v-icon> 40%</small>
    </v-tab>
    <v-tab>
      <v-progress-linear class="mt-2" value="56" color="green"></v-progress-linear>
      <small><v-icon >mdi-food-fork-drink</v-icon> 56%</small>
    </v-tab>
    <v-tab>
      <v-progress-linear class="mt-2" value="30" color="orange"></v-progress-linear>
      <small><v-icon >mdi-home-city</v-icon> 30%</small>
    </v-tab>
    <v-tab>
      <v-progress-linear class="mt-2" value="85" color="green"></v-progress-linear>
      <small><v-icon >mdi-human-queue</v-icon> 85%</small>
    </v-tab>
    
  </v-tabs>

</div>
</template>

<style>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

#map-container{
  /* position:fixed;
  top: 0px;
  bottom:0px;
  left:0px;
  right:0px;
  width:100%; */
  z-index:0;
} 

.open-list-res-map{
  position: absolute;
  left:15px;
  top: 15px;
}
.btn-tool-map{
  display:none;
}
.dialogSearchGeo .btn-tool-map{
  display:unset;
  position:fixed; 
  z-index:1000; 
  left: 6%; 
  right:6%; 
  width:88%;
}
.dialogSearchGeo .btn-tool-map.tool-map1{
  bottom:85px;
}
.dialogSearchGeo .btn-tool-map.tool-map2{
  bottom:40px;
}

.slider-geo-block{
  position: absolute;
  top: 70px;
  z-index: 2;
  background-color: #f5f5f5e3;
  color: black;
  height: 40px;
  width: 300px;
  border-radius: 4px;
  padding: 3px 5px 0 5px;
  right: 15px;
}
.theme--dark .slider-geo-block{
  background-color: #0f0f0fe3;
}

.toolbar-map-bootom{
  position:absolute;
  bottom:0px;
}
.toolbar-map-bootom .v-tabs-slider-wrapper{
  display:none;
}

.theme--light .toolbar-map-bootom .v-tab--active,
.theme--light .toolbar-map-bootom .v-tab--active .v-icon{
  color:rgb(44, 44, 44)!important;
}

.theme--dark .toolbar-map-bootom .v-tab--active,
.theme--dark .toolbar-map-bootom .v-tab--active .v-icon{
  color:white!important;
}
.toolbar-map-bootom .v-slide-group__prev{
  display:none !important;
}
.toolbar-map-bootom .v-tab{
  min-width: 60px!important;
  padding: 1%;
}
.theme--dark.v-tabs > .v-tabs-bar{
  background-color:rgba(10, 10, 10, 0.9)
}

.text-search-geo{
  z-index:2000; 
  position:absolute; 
  top:15px; 
  right:15px !important;
  width: 300px;
  max-width:75%;
}

@media (min-width: 960px) {
  
}
@media (max-width: 960px) {
  .toolbar-map-bootom .v-progress-linear{
    max-width: 90px;
  }
}
@media (max-width: 500px) {
  .toolbar-map-bootom .v-progress-linear{
    max-width: 56px;
  }
}
</style>


<script>

//import axios from "axios"
import core from '../../plugins/core.js'
const config = require('../../config/' + process.env.NODE_ENV)

import L from 'leaflet';
import {LMap, LTileLayer, LMarker, LCircle, LPopup} from 'vue2-leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'

import '@/assets/js/dist/leaflet.awesome-markers.js'
import '@/assets/js/dist/fonts/font-awesome.css'
import '@/assets/js/dist/leaflet.awesome-markers.css'
//import '@/assets/js/dist/leaflet.css' 

import ListNominatimResult from '@/components/offer/ListNominatimResult.vue'

import PopupMarkerEntity from '@/components/map/PopupMarkerEntity.vue'

import nominatim from '../../services/nominatim.js'



export default {
  name: 'home', 
  components: {
    LMap, LTileLayer, LMarker, LCircle, LPopup, ListNominatimResult,
    'v-marker-cluster' : Vue2LeafletMarkerCluster,
    PopupMarkerEntity 
  },
  props: [''],
  data: () => ({
    search: "",
    results: 0,
    showMap: false,

    city: "",
    address: "",
    lat: 46.95026224218562, 
    lng: 2.5913274703850764,

    rootMarker: {
      lat: 46.95026224218562,
      lng: 2.5913274703850764,
    },
    rootCircle: {
      radius: 100000
    },
    rootRadius: 100000,

    markerClusterList: [],

    loadPlaces: false,
    places: null,
    cityAddressPopup: "",

    zoom:10,
    mapCenter: L.latLng(46.95026224218562, 2.5913274703850764),
    url : config.mapTilesUrl,
    attribution:'', //&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    popupContent: "ok",

    clusterOptions:  { showCoverageOnHover: false, maxClusterRadius: 30 },
    searchPlaceStr: "",

    mapWidth: 0,
    menuTopHeight: 64,
  }),
  mounted: async function(){
    //if(this.$store.state.auth.user != null)
    this.init(this.$store.state.auth.user)

    this.$root.$off('invalidateSizeSearchGeoBlock').$on('invalidateSizeSearchGeoBlock', () => { 
      //console.log("on invalidateSizeSearchGeoBlock")
      this.invalidateSize()
    })

    this.$root.$off('showEntityOnMap').$on('showEntityOnMap', (entity) => { 
      this.$refs.map.mapObject.setZoom(15)

      setTimeout(() => {
        this.$refs.map.mapObject.panTo(entity.coordinates)
        setTimeout(() => {
          this.$refs.map.mapObject.invalidateSize()
          this.markerClusterList.forEach((markerItem) => {
            if(markerItem.id == entity._id)
              markerItem.marker.fire('click')
          })
        }, 300)
        
      }, 300)

      //console.log("showEntityOnMap", this.$refs.markerCluster.mapObject.getAllClusters())
    })

    this.calcToolbarWidth()
   
  },
  methods: {
    async init(user){ //init est appelé par un watcher qui attend que le user soit chargé
      console.log("init SEARCHGEOBLOCK")
      if(user.city != null) this.city = user.city
      if(user.address != null ) this.address = user.address

      if(this.$store.state.market.requestParams.coordinates != null){
        //console.log("get coordinate from requestParams")
        this.lat = this.$store.state.market.requestParams.coordinates[0]
        this.lng = this.$store.state.market.requestParams.coordinates[1]
      }
      if(user.coordinates != null && user.coordinates != "") {
        //console.log("get coordinate from user")
        this.lat = user.coordinates[0]
        this.lng = user.coordinates[1]
      }
      this.mapCenter = L.latLng(this.lat, this.lng)
      this.showMap = true
      this.invalidateSize()

      this.saveAddress()

      this.updateMarkerMap()
    },
    calcToolbarWidth(){
      let el = document.getElementById("col-map")
      console.log("el", el)
      this.mapWidth = el == null ? 0 : el.offsetWidth
    },
    updateMarkerMap(){
        if(this.$refs.markerCluster == null) return 

        let mks = this.$refs.markerCluster.mapObject
        if(mks != null) mks.clearLayers()
        this.markerClusterList = []

        this.cntEntityOnMap = 0
        this.$store.state.map.results.forEach((entity) => {
          if(entity.coordinates != null){
            //this.cntEntityOnMap++
            //console.log("#MAIN updateMarkersEntitys uid:", entity.uid)
            let icon = this.markerIconEntity(entity)
            //if(window.isDev) { console.log("show entity on map", entity.name, icon); }
            let marker = L.marker([entity.coordinates[0], entity.coordinates[1]], { icon: icon })
            marker.on('click', () => {
              if(window.isDev) { console.log("click marker", entity.uid); }
              this.entityMapPopup = entity
              setTimeout(()=>{
                let popup = document.getElementById("popup-entity-"+entity._id).innerHTML
                marker.bindPopup("<div id='popup"+entity._id+"' style='min-width:300px'>" +
                                    popup +
                                "</div>")
                marker.openPopup()
                let el = document.querySelector(".leaflet-popup-content #btn-show-details-entity-" + entity._id)//.forEach((el)=>{
                console.log("bind click popup", el, entity._id)
                el.addEventListener("click", ()=>{
                  console.log("open ppup", entity._id)
                  this.$root.$emit('openDialogEntity', entity)
                })
                //})
                // document.getElementsByClassName("btn-send-private-" + entity.uid).forEach((el)=>{
                //   el.addEventListener("click", async ()=>{
                //     router.push('/private/sendto/'+entity.uid+'/'+entity.name)
                //   })
                // })
              }, 300)
              
            })
            
            mks.addLayer(marker)
            this.markerClusterList.push({ id: entity._id, marker: marker })
          }
        })
    },
    async saveAddress(){
      this.$root.$emit("onChangeSearchGeo", { coordinates: [this.lat, this.lng], 
                                              radius: this.rootRadius, 
                                              fetch: true })
    },
    searchPlace: async function(){
      let query = this.searchPlaceStr + " FR"
      this.loadPlaces = true
      this.places = []
      this.showMap = true
      this.invalidateSize()
      nominatim.search(query, (res)=>{
        this.loadPlaces = false
        if(res.error == false){
          this.places = res.data
          this.setNewCenter(this.places[0].lat, this.places[0].lng)
        }
      })
    },
    setNewCenter: function(lat, lng){
      console.log("setNewCenter", lat, lng)
      this.$refs.map.mapObject.invalidateSize();

      lat = parseFloat(lat)
      lng = parseFloat(lng)
      this.mapCenter = [lat, lng]
      this.lat = lat
      this.lng = lng
      this.updateRadius()
      //this.zoom = 14
      
      setTimeout(() => {
        this.$refs.map.mapObject.invalidateSize()
      }, 100)
      setTimeout(() => {
        //this.$refs.marker.mapObject.openPopup()
        this.$refs.map.mapObject.invalidateSize()
      }, 500)
      
    },
    dragend(e){ 
      e.target.openPopup()
      this.lat = e.target.getLatLng().lat
      this.lng = e.target.getLatLng().lng

      this.saveAddress()
    },
    rootMarkerDrag: function(){
      this.lat = this.$refs.rootMarker.mapObject._latlng.lat
      this.lng = this.$refs.rootMarker.mapObject._latlng.lng
    },
    invalidateSize(){ 
        setTimeout(() => { 
          this.$refs.map.mapObject.invalidateSize()
          this.$refs.rootMarker.mapObject.openPopup()
        }, 400) 
    },

    decrement: function(){
      if(this.rootRadius <= 1000) return
      this.rootRadius -= 1000
      this.updateRadius(false)
    },
    increment: function(){
      this.rootRadius += 1000
      this.updateRadius(false)
    },
    updateRadius: async function(loadContent=true){
      console.log("updateRadius", loadContent)
      this.rootCircle.radius = this.rootRadius
      var tCircle = L.circle(L.latLng(this.lat, this.lng), { radius: this.rootCircle.radius })
      tCircle.addTo(this.$refs.map.mapObject)
      this.$refs.map.mapObject.fitBounds(tCircle.getBounds())
      tCircle.remove()
      this.$refs.rootMarker.mapObject.openPopup()
      this.$root.$emit("onChangeSearchGeo", { coordinates: [this.lat, this.lng], radius: this.rootRadius })
    },
    monyToEuro(amount){ return amount * config.monyToEuro },
    nl2br: function(str, is_xhtml){
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'
      let newStr = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
      let maxLength = 40
      let p = newStr.length > maxLength ? '...' : ''
      newStr = newStr.substr(0, maxLength) + p
      return newStr
    },
    showDialogDetails(res) { 
      this.$root.$emit("openDialogDetails", res)
    },
    baseUrl(){ return core.baseUrl() },
    markerIconEntity: function(entity){
        //let avatar = core.avatarUrl(page.uid) //'markers/icon-popup.png'
        let color = "blue"
        let icon = "point"

        if(entity.dataType != null){
          color = this.$store.state.map.dataTypeConfigDefault[entity.dataType].color
          icon = this.$store.state.map.dataTypeConfigDefault[entity.dataType].icon
        }

        return L.AwesomeMarkers.icon({
                  icon: icon,
                  prefix: 'fa',
                  markerColor: color
              })
        // return L.icon({
        //     iconUrl: '/marker/marker-'+color+'.png',
        //     shadowUrl: '/marker/marker-shadow.png',
        //     iconSize: [24, 40],
        //     iconAnchor: [12, 40],
        //     popupAnchor: [1, -35],
        //     shadowSize: [40, 40],
        //     className: '' //connected-' + this.statusConnected(page)
        // });

    },
    calcTopBarHeight(){
      let el = document.getElementById('top-bar')
      this.menuTopHeight = el ? el.offsetHeight : 64
    }
  },
  computed: {
    markerMain(){ 
      //let color = "blue"
      return L.AwesomeMarkers.icon({
                  icon: 'circle',
                  prefix: 'fa',
                  extraClass: '2x',
                  markerColor: 'black'
              })
    },
    marker(){ 
      let color = "orange"
      return L.icon({
        iconUrl: '/marker/marker-'+color+'.png',
        shadowUrl: '/marker/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });
    },
    
  },
  
  watch: {
    
      '$store.state.market.requestParams.coordinates': { immediate: true, 
          async handler () {
            // if(this.$store.state.market.requestParams.coordinates == null) return
            // let lat = this.$store.state.market.requestParams.coordinates[0]
            // let lng = this.$store.state.market.requestParams.coordinates[1]
            // this.setNewCenter(lat, lng)
          }
      },
      '$store.state.auth.user._id': { immediate: true, 
          async handler () {
              
            if(this.$store.state.auth.isLogin){
              console.log("user logged !", this.$store.state.auth.user)
              this.init(this.$store.state.auth.user)
            }
          }
      },
      '$store.state.map.results': { immediate: true, 
          async handler () {
              this.updateMarkerMap()
          }
      },
      '$vuetify.breakpoint.width': { immediate: true, 
          async handler () {
              this.calcToolbarWidth()
              this.calcTopBarHeight()
          }
      },
      '$vuetify.breakpoint.height': { immediate: true, 
          async handler () {
              this.calcTopBarHeight()
          }
      },
  }, 
}
</script>